import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { filter, findIndex, remove } from 'lodash'

const initialState = {
  selectedVehicles: [] as any[],
}

const serviceTypesSmartPlannerSlice = createSlice({
  name: 'serviceTypesForBundle',
  initialState,
  reducers: {
    updateServiceTypesResponse: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        ...action.payload,
      }
    },
    updateAvailableVehicles: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        selectedVehicles: action.payload.availableVehicleIds || [],
        allManualVehicles: action.payload.vehicleTypeIds || [],
      }
    },
    updateSelectedVehicle: (state, action: PayloadAction<any>) => {
      const { selectedVehicles } = state
      const isExist = findIndex(selectedVehicles, (id) => id === action.payload)

      if (isExist >= 0) {
        remove(state.selectedVehicles, { id: action.payload })
      } else {
        state.selectedVehicles.push(action.payload)
      }
    },
  },
  extraReducers: () => {},
})

export const serviceTypesSmartPlannerActions = serviceTypesSmartPlannerSlice.actions

export default serviceTypesSmartPlannerSlice
